import { useUserMe } from 'api/goodtrust/user'
import { MyAvatar } from 'components/avatar/Avatar'
import { Button } from 'components/button/Button'
import { BlankTopNavigation } from 'components/header/topNavigationLandingPage/BlankTopNavigation'
import { Icon } from 'components/icon'
import { useMobileNavigationModal } from 'components/navigation/MobileNavigationModal'
import { Navigation } from 'components/navigation/Navigation'
import { describeNavigation } from 'logic/navigation/describe'
import { NavigationVariant } from 'logic/navigation/types'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { pxToRemUnit } from 'utils/styled'

export function TopNavigationLandingPage(props: {
  variant: NavigationVariant
  hideNavigation?: boolean
  noSpacingBelow?: boolean
}) {
  const { data } = useUserMe()
  const { isLogged } = useAuth()

  const paths = describeNavigation().getLandingPageStructure({ isLogged, variant: props.variant })

  const isProfileReady = isLogged && data?.json

  const [mobileModal, isMobileModalOpen, openMobileModal, closeMobileModal] =
    useMobileNavigationModal({
      paths,
    })

  return (
    <BlankTopNavigation noSpacingBelow={props.noSpacingBelow}>
      {mobileModal}
      {!props.hideNavigation && (
        <>
          <Navigation className="desktop-only" paths={paths} />
          <Button
            type="button"
            variant="text"
            tone="gray"
            className="mobile-only"
            onClick={isMobileModalOpen ? closeMobileModal : openMobileModal}
          >
            <Icon name={isMobileModalOpen ? 'Close' : 'MenuOpen'} size="1rem" />
          </Button>
        </>
      )}
      {isProfileReady && (
        <Button
          type="button"
          variant="text"
          tone="dark"
          href="/me/profile"
          leftIcon={
            <>
              <MyAvatar size={40} placeholderFontSize={pxToRemUnit(14)} className="desktop-only" />
              <MyAvatar size={28} placeholderFontSize={pxToRemUnit(14)} className="mobile-only" />
            </>
          }
        />
      )}
    </BlankTopNavigation>
  )
}
