import { BasicTextImageSection } from 'components/content/basicTextImageSection/BasicTextImageSection'
import { TranslatableLink } from 'components/TranslatableLink'
import { Trans, useTranslation } from 'next-i18next'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { useContentfulImages } from 'utils/contentful/ContentfulImagesContext'
import { numberRange } from 'utils/general'
import { HorizontalPlacement } from 'utils/types'

export const SecuritySection = ({ imagePlacement }: { imagePlacement?: HorizontalPlacement }) => {
  const { t } = useTranslation()
  const images = useContentfulImages()
  const { isLogged } = useAuth()

  const icons = ['LockClosed', 'CreditCardLocked', 'Shield'] as const
  const securityPerks = numberRange(3).map((i) => ({
    title: t(`common.section_security.perk_${i}.title`),
    description: (
      <Trans
        t={t}
        i18nKey={`common.section_security.perk_${i}.description`}
        components={[
          <TranslatableLink href={isLogged ? '/me/digital-vault' : '/signup'} key={0} />,
        ]}
      />
    ),
    icon: icons[i - 1] ?? 'LockClosed',
    links: ['me/digital-vault'],
  }))

  return (
    <BasicTextImageSection
      orientation={imagePlacement === 'right' ? 'left' : 'right'}
      caption={t('common.section_security.caption')}
      title={t('common.section_security.title')}
      desc={t('common.section_security.description')}
      perks={securityPerks}
      image={images.section_security}
    />
  )
}
