import { InterpolationPrimitive } from '@emotion/serialize'
import { Block } from 'components/Block'
import { Button } from 'components/button/Button'
import { Icon, IconName } from 'components/icon'
import { ContentfulImage, ContentfulImageProps } from 'components/Image'
import { HStack, Stack } from 'components/Stack'
import { Caption, Text, Title, Title_h3_default, TTitleVariant } from 'components/Typography'
import React, { ReactNode } from 'react'
import { colors } from 'utils/colors'
import { useWindowSize } from 'utils/hooks'
import {
  SBasicTextImagePicture,
  SBasicTextImagePictureContainer,
  SBasicTextImageSection,
  SBasicTextImageTextContainer,
  SIconWrapper,
} from './BasicTextImageSection.styled'

export function BasicTextImageSection({
  className,
  orientation = 'left',
  'data-testid': dataTestId,
  caption,
  title,
  titleVariant,
  desc,
  descNoMaxWidth,
  perks,
  textWrapperCSS,
  image,
  imageSubtitle,
  imageWrapperCSS,
  actionTitle,
  actionIcon,
  onAction,
  belowDescElement,
}: {
  className?: string
  orientation?: 'left' | 'right'
  'data-testid'?: string
  caption?: string
  perks?: {
    title: string
    description: ReactNode
    icon: IconName
  }[]
  title: string
  titleVariant?: TTitleVariant
  desc: ReactNode
  descNoMaxWidth?: boolean
  textWrapperCSS?: InterpolationPrimitive
  image?: ContentfulImageProps
  imageSubtitle?: string
  imageWrapperCSS?: InterpolationPrimitive
  actionTitle?: string
  actionIcon?: React.ReactElement
  onAction?: () => void
  belowDescElement?: ReactNode
}) {
  const { isDesktop } = useWindowSize()
  if (!isDesktop) orientation = 'left'

  const pictureJsx = (
    <SBasicTextImagePictureContainer orientation={orientation} css={imageWrapperCSS}>
      {imageSubtitle && <Caption css={{ margin: '0.5rem 0' }}>{imageSubtitle}</Caption>}

      <SBasicTextImagePicture orientation={orientation}>
        <ContentfulImage {...image} />
      </SBasicTextImagePicture>
    </SBasicTextImagePictureContainer>
  )

  return (
    <Block className={className} css={{ '--width': '1178px', overflow: 'hidden' }}>
      <SBasicTextImageSection orientation={orientation}>
        {!isDesktop && (
          <>
            {caption && <Caption>{caption}</Caption>}
            <Title
              variant={titleVariant ?? Title_h3_default}
              as="h4"
              css={{ marginBottom: '0.5rem' }}
            >
              {title}
            </Title>
          </>
        )}

        <SBasicTextImageTextContainer orientation={orientation} css={textWrapperCSS}>
          {isDesktop && (
            <>
              {caption && <Caption css={{ marginBottom: '1.5rem' }}>{caption}</Caption>}
              <Title
                variant={titleVariant ?? Title_h3_default}
                as="h3"
                css={{ marginBottom: '1.5rem' }}
              >
                {title}
              </Title>
            </>
          )}

          {!isDesktop && pictureJsx}

          <Text as="div" css={descNoMaxWidth || { maxWidth: '26rem' }}>
            {desc}
          </Text>
          {perks && !!perks.length && (
            <Stack space="1.25rem" css={{ marginTop: '2rem' }}>
              {perks.map((perk) => (
                <HStack key={perk.title} space="1.25rem">
                  <SIconWrapper>
                    <Icon name={perk.icon} size="1.5rem" color={colors.blue[500]} />
                  </SIconWrapper>
                  <Stack>
                    <Text color={colors.gray[100]} variant="bodyBold">
                      {perk.title}
                    </Text>
                    <Text variant="bodyMedium">{perk.description}</Text>
                  </Stack>
                </HStack>
              ))}
            </Stack>
          )}
          {!!belowDescElement && belowDescElement}

          {onAction && actionTitle && (
            <div css={{ marginTop: '1.5rem' }}>
              <Button
                type="button"
                variant="text"
                size="large"
                data-testid={dataTestId}
                leftIcon={actionIcon}
                onClick={onAction}
                css={{ whiteSpace: 'normal' }}
              >
                {actionTitle}
              </Button>
            </div>
          )}
        </SBasicTextImageTextContainer>

        {isDesktop && pictureJsx}
      </SBasicTextImageSection>
    </Block>
  )
}
