import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { BREAKPOINTS, pxToRem } from 'utils/styled'

export const SBasicTextImageSection = styled.div<{
  orientation: 'left' | 'right'
}>`
  display: grid;
  grid-gap: 11.75rem;
  grid-auto-flow: dense;

  ${({ orientation }) =>
    orientation === 'right'
      ? `
        padding-bottom: 3rem;
        grid-template-columns: 1fr 1.05fr;
      `
      : `
        padding-bottom: 2rem;
        grid-template-columns: 1.05fr 1fr;
      `}

  @media ${BREAKPOINTS.LG.max} {
    grid-gap: 6rem;
  }

  @media ${BREAKPOINTS.MD.max} {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
  }
`

export const SBasicTextImageTextContainer = styled.div<{
  orientation: 'left' | 'right'
}>`
  margin: auto 0;
  ${({ orientation }) => (orientation === 'right' ? `grid-column-start: 2;` : null)}

  @media ${BREAKPOINTS.MD.max} {
    grid-row-start: 3;
    grid-column-start: 1;

    ${({ orientation }) => (orientation === 'right' ? `margin-left: 5%;` : `margin-right: 5%;`)}
  }
`

export const SBasicTextImagePictureContainer = styled.div<{
  orientation: 'left' | 'right'
}>`
  height: 100%;
  width: 100%;
  position: relative;
  margin: auto 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  ${({ orientation }) =>
    orientation === 'left' ? `flex-direction: column-reverse;` : `flex-direction: column;`}

  @media ${BREAKPOINTS.MD.max} {
    padding: 0;
    // padding for the blue rectangle
    padding-bottom: ${pxToRem(24)}rem;
  }
`

export const SBasicTextImagePicture = styled.div<{
  orientation: 'left' | 'right'
}>`
  position: relative;
  width: 100%;

  @media ${BREAKPOINTS.MD.max} {
    max-width: 26rem;
  }

  &:before {
    content: '';
    position: absolute;

    @media ${BREAKPOINTS.LG.min} {
      ${({ orientation }) =>
        orientation === 'left'
          ? css`
              top: 15%;
              left: 20%;
              right: calc(-1 * var(--space-width));
              bottom: -2rem;
            `
          : css`
              top: 20%;
              left: calc(-1 * var(--space-width));
              right: 20%;
              bottom: -3rem;
            `}
    }

    @media ${BREAKPOINTS.MD.max} {
      top: ${pxToRem(20)}rem;
      left: calc(-1 * var(--space-width));
      right: ${pxToRem(54)}rem;
      bottom: -${pxToRem(24)}rem;
    }

    z-index: -1;

    border: 2px solid var(--c-blue100);
  }
`

export const SIconWrapper = styled.div`
  padding: 0.625rem;
  border-radius: 50%;
  background-color: var(--c-blue100);
`
